import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import '../css/navbar.css';
import logo from "../media/logo.png";
import banner1 from "../media/banner_1.jpg";
import banner2 from "../media/banner_2.jpg";
import '../services/home.js'
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Navbar() {

    const offcanvasTop = () => {
        $('#offcanvasTop').addClass("show");
    }
    return (
        <>
            <section class="home">
                <nav class="navbar navbar-expand-lg p-0">

                    {/* <!-- mobile-menu --> */}
                    <div class="col">
                        <div data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop">
                            <div class="line"></div>
                            <div class="line"></div>
                            <div class="line"></div>
                        </div>
                    </div>

                    <div class="col  logo-mobile" style={{ display: "none !important" }}>
                        <div class="logo col-3 h-100">
                            <a href="/" class="mb-0">
                                <img src={logo} alt="logo" />
                            </a>
                            {/* <!-- <h1 class="mb-0">
                        
                    </h1> --> */}
                        </div>
                    </div>

                    <div class="col" style={{display:'none'}}>
                        <div class="offcanvas offcanvas-top close-icon" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel" style={{ height: '100vh' }}>
                            <div class="offcanvas-header d-flex justify-content-center">
                                <h4>
                                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" style={{ marginTop: '21px' }} onClick={offcanvasTop}></button>
                                </h4>

                            </div>
                            <div class="offcanvas-body">
                                <div class="navbar-nav">
                                    <center>
                                        <h4>
                                            <a class="nav-link active" style={{ textDecoration: 'none' }} aria-current="page" href="#">Home</a>
                                        </h4>
                                        <h4>
                                            <a class="nav-link" href="#">Features</a>
                                        </h4>
                                        <h4>
                                            <a class="nav-link" href="#">Pricing</a>
                                        </h4>
                                        <h4>
                                            <a class="nav-link" href="#">Disabled</a>
                                        </h4>
                                        <h4>
                                            <a class="nav-link" href="#">Features</a>
                                        </h4>
                                        <h4>
                                            <a class="nav-link" href="#">Pricing</a>
                                        </h4>
                                        <h4>
                                            <a class="nav-link" href="#">Disabled</a>
                                        </h4>
                                        <h4>
                                            <a class="nav-link" href="#">Disabled</a>
                                        </h4>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- mobile-menu --> */}

                    <div class="offcanvas navbar-collapse flex-row justify-content-between px-3" id="offcanvasTop">
                        <div class="logo col-3 h-100">
                            
                            {/* <h1 class="mb-0">
                            <img src="static/media/logo.png" alt="logo">
                        </h1> */}
                        </div>
                        <ul class="col-8 h-100 navbar-nav-main">
                            <li id="home">
                                <a href="/">
                                    Home
                                </a>
                            </li>
                            <li id="aboutUs">
                                <a href="/aboutUs">
                                    About Us
                                </a>
                            </li>
                            <li id="ourProjects">
                                <a href="/ourProjects">
                                    Our Projects
                                </a>
                            </li>
                            <li id="services">
                                <a href="/services">
                                    Services
                                </a>
                            </li>
                            <li id="coProfile">
                                <a href="/coProfile">
                                    Co-Profile
                                </a>
                            </li>
                            <li id="contactUs">
                                <a href="/contactUs">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </section>
        </>
    );
}

export default Navbar;
