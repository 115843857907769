import logo from './logo.svg';
import React, { lazy, Suspense, useEffect } from "react";
import './App.css';
import { BrowserRouter as Router, Route, } from "react-router-dom";
import { Switch, useHistory } from "react-router-dom";
import Navbar from './common/navbar';
const HomePage = lazy(() =>
  import("./components/home.jsx")
);
const AboutUs = lazy(() =>
  import("./components/aboutUs.jsx")
);
const ContactUs = lazy(() =>
  import("./components/contactUs.jsx")
);
const OurProjects = lazy(() =>
  import("./components/ourProjects.jsx")
);
const Services = lazy(() =>
  import("./components/services.jsx")
);
const CoProfile = lazy(() =>
  import("./components/coprofile.jsx")
);
function App() {
  return (
    <Router
      getUserConfirmation={(message, callback) => {
        const allowTransition = window.confirm(message);
        callback(allowTransition);
      }}
    >
        <Switch>
          <Route exact path="/">
            <HomePage/>
          </Route>

          <Route exact path="/aboutUs">
            <AboutUs/>
          </Route>

          <Route exact path="/contactUs">
            <ContactUs/>
          </Route>

          <Route exact path="/ourProjects">
            <OurProjects/>
          </Route>

          <Route exact path="/services">
            <Services/>
          </Route>

          <Route exact path="/coProfile">
            <CoProfile/>
          </Route>
        </Switch>

    </Router>
  );
}
function RenderNavbar() {
  return <Navbar />;
}
export default App;
